import React from 'react'
import PropTypes from 'prop-types'
import { Div, Text } from 'atomize-jnh'

const EngageMessage = ({ title, subtitle }) => {
  return (
    <Div p={{ t: { md: '3rem', lg: '2.5rem' } }}>
      <Text
        tag="h1"
        textAlign={{ xs: 'center', md: 'left' }}
        p={{ l: { xs: '0.5rem', md: '0' }, r: { xs: '0.5rem' } }}
        m={{ b: { xs: '24px', md: '.5rem' }, t: { md: '86px' } }}
        textSize={{ xs: 'heading', md: 'title', lg: 'display1' }}
        textColor="#fff">
        {title}
      </Text>
      <Text
        tag="p"
        p={{ l: { xs: '.4rem', md: '0' }, r: { xs: '.4rem', md: '0' } }}
        textAlign={{ xs: 'center', md: 'justify' }}
        style={{ lineHeight: '130%', fontStyle: 'italic' }}
        textSize={{ xs: 'body', md: 'body', lg: 'subheader' }}
        textColor="#fff"
        w={{ md: '80%' }}>
        {subtitle}
      </Text>
    </Div>
  )
}

EngageMessage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default EngageMessage
