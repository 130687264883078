import React from 'react'
import PropTypes from 'prop-types'
import { Div } from 'atomize-jnh'

const Illustration = props => {
  Illustration.propTypes = {
    img: PropTypes.object
  }

  const { img } = props

  return (
    <Div
      align="center"
      w="100%"
      h={{ xs: '25rem', lg: '24rem' }}
      bgImg={img}
      m={{ t: { xs: '2rem', sm: '0', md: '0', lg: 0 } }}
      bgSize="contain"
      bgRepeat="no-repeat"
    />
  )
}

export default Illustration
