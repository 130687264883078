/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import PropTypes from 'prop-types'
import { Div, Container, Text, Row, Col } from 'atomize-jnh'

function IconDescriptionList(props) {
  IconDescriptionList.propTypes = {
    descriptions: PropTypes.descriptions,
    bg: PropTypes.string,
    circleSize: PropTypes.string
  }

  const { bg, descriptions, circleSize } = props

  function circleDiameterInterface() {
    if (circleSize === 'medium') {
      return {
        xs: '120px'
      }
    } else if (circleSize === 'large') {
      return {
        xs: '180px'
      }
    } else {
      return {
        xs: '120px'
      }
    }
  }

  return (
    <Div bg={bg} p={{ t: '48px' }}>
      <Container>
        <Div p={{ t: '64px', b: '64px' }}>
          <Row justify="center" align="center">
            {descriptions.map((value, index) => {
              return (
                <Col size={{ xs: '12', md: '4' }} key="index">
                  <Div
                    bg="#fff"
                    rounded="100%"
                    w={circleDiameterInterface()}
                    h={circleDiameterInterface()}
                    m="0 auto"
                  />
                  {value.title && (
                    <Text
                      h={{ xs: '30px', md: '20px' }}
                      w="90%"
                      m="24px auto"
                      textColor="#4D68F0"
                      textSize="heading"
                      textAlign="center">
                      {value.title}
                    </Text>
                  )}
                  <Text
                    h={{ xs: '75px', md: '0' }}
                    w="90%"
                    m="24px auto"
                    textColor="#6B7C93"
                    textSize="body"
                    textAlign="center">
                    {value.description}
                  </Text>
                </Col>
              )
            })}
          </Row>
        </Div>
      </Container>
    </Div>
  )
}

export default IconDescriptionList
