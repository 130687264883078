import './index.scss'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import SEO from '../components/Seo'
import Layout from '../layouts/'
import { injectIntl } from 'gatsby-plugin-intl'
import PageTop from '../components/PageTop'
import IconDescriptionList from '../components/IconDescriptionList'
import { Container, Div, Text, Col, Row } from 'atomize-jnh'
import Button from '../components/Button'
import img from '../assets/ilustracion-landing-01.svg'

const WhatIsBrokerProgram = () => {
  return (
    <Div bg="#E9F3FE" p={{ b: { xs: '50px', md: '150px' } }}>
      <Container>
        <Row>
          <Col size={{ md: '4' }}>
            <Text
              tag="h1"
              textAlign={{ xs: 'center', md: 'left' }}
              p={{ l: { xs: '0.5rem', md: '0' }, r: { xs: '0.5rem' } }}
              m={{ b: { xs: '24px', md: '.5rem' }, t: { md: '86px' } }}
              textSize={{ xs: 'heading', md: 'title', lg: 'display1' }}
              textColor="#4D68F0">
              ¿Qué es ser un Broker Orionx?
            </Text>
            <Text textColor="#6B7C93" textAlign="justify">
              Ser un Broker Orionx es tener la posibilidad de ganar un porcentaje de la comisión
              recibida por Orionx por cada transacción que realicen los usuarios que tu hayas
              referido al sitio. Es una forma rápida, simple y segura de aumentar tus ingresos. Sólo
              debes compartir el link de registro con quienes quieras recomendar para usar Orionx.
            </Text>
          </Col>
          <Col d="flex" justify="flex-end" size={{ md: '8' }}>
            <Div
              align="flex-end"
              w="400px"
              m={{ t: '50px' }}
              h={{ xs: '25rem', lg: '24rem' }}
              bgImg={img}
              bgSize="contain"
              bgRepeat="no-repeat"
            />
          </Col>
        </Row>
      </Container>
    </Div>
  )
}

const IndexPage = ({ intl, location }) => {
  const [isConfirmed, setConfirmed] = useState()
  const [title, setTitle] = useState(
    'Invita a tus amigos a usar Orionx y gana % de comisión por sus transacciones'
  )
  const [subtitle, setSubtitle] = useState(
    'Si quieres participar como Broker, acepta las condiciones del servicio y comienza a ganar junto a Orionx.'
  )

  function handleConfirm() {
    setTitle('Introducing Brokers')
    setSubtitle('Invita referidos con tu lin y obten 30% de la comisión')
    setConfirmed(true)
  }

  return (
    <Layout>
      <SEO
        location={location}
        defaultImage="https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&w=1952&q=60&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D"
        title={intl.formatMessage({ id: 'title' })}
      />
      <>
        <PageTop
          title={title}
          subtitle={subtitle}
          img={img}
          CallToAction={
            !isConfirmed && (
              <Div
                d={{ xs: 'flex', md: 'inline-block' }}
                justify={{ xs: 'center', md: 'flex-start' }}
                p={{ t: '24px', b: '24px' }}>
                <Button onClick={handleConfirm} content="Confirmar" />
              </Div>
            )
          }
        />

        {!isConfirmed && (
          <Div>
            <IconDescriptionList
              bg="#E9F3FE"
              circleSize="large"
              descriptions={[
                {
                  title: 'Genera',
                  description: 'Generas tu link de referido aceptando los terminos y condiciones'
                },
                {
                  title: 'Invita',
                  description:
                    'Puedes compartir el link para que las personas se registren con tu link'
                },
                {
                  title: 'Gana',
                  description:
                    'Cada vez que uno de esos usuarios que invitaste hacen alguna transacción tu recibes un % de la comisión'
                }
              ]}
            />

            <WhatIsBrokerProgram />
          </Div>
        )}
      </>
    </Layout>
  )
}

IndexPage.propTypes = {
  location: PropTypes.object,
  intl: PropTypes.object
}

export default injectIntl(IndexPage)
