import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Div, Container } from 'atomize-jnh'
import SimpleMessage from './SimpleMessage'
import Illustration from './Illustration'

const Hero = props => {
  Hero.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    img: PropTypes.object,
    CallToAction: PropTypes.object
  }

  const { title, subtitle, img, CallToAction } = props

  function renderMessage() {
    return <SimpleMessage title={title} subtitle={subtitle} />
  }

  return (
    <Div
      bg="linear-gradient(30deg, rgba(12,110,246,1) 30%, rgba(76,193,249,1) 100%, rgba(76,193,249,1) 100%)"
      p={{
        t: { xs: '3rem', md: '3rem', lg: '4rem' },
        b: { xs: '2rem', md: '2rem' },
        l: { lg: '1rem' },
        r: { lg: '1rem' }
      }}>
      <Container>
        <Row>
          <Col size={{ xs: '12', md: '7', lg: '7' }}>
            {renderMessage()}
            {CallToAction && CallToAction}
          </Col>
          <Col d="flex" justify="center" size={{ xs: '12', md: '5' }}>
            <Illustration img={img} />
          </Col>
        </Row>
      </Container>
    </Div>
  )
}

export default Hero
